import { useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { logAmplitudeEvent } from '../amplitude';

interface PaymentModalProps {
  label: string; // Название для отображения в платеже (например, "150 Coins")
  amount: number; // Сумма в центах для отображения в UI
  payment_plan_id: number; // ID подписки
  onPaymentSuccess: () => void; // Пропс для передачи функции при успешной оплате
}

const PaymentModal: React.FC<PaymentModalProps> = ({ label, amount, payment_plan_id, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  const purchasef = localStorage.getItem('purchasef');

  const handlePaymentButtonClick = () => {
    logAmplitudeEvent('e.4.0 - Call stripe payment subscribe', { component: 'e.4.0 - Call stripe payment subscribe' });
  };

  useEffect(() => {
    const mutationCallback = (mutationsList: MutationRecord[]) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const googlePayButton = document.querySelector('.GooglePayButton');
          if (googlePayButton) {
            (googlePayButton as HTMLElement).style.maxHeight = '128px';
            observer.disconnect();
          }
        }
      }
    };

    const observer = new MutationObserver(mutationCallback);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: label,
          amount: amount,
        },
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(true);
        } else {
          const noCardsEventSent = localStorage.getItem('noCardsEventSent');
          if (!noCardsEventSent) {
            logAmplitudeEvent('e.4.0 - No cards', { component: 'e.4.0 - No cards' });
            console.log('No eligible cards available for payment.');
            localStorage.setItem('noCardsEventSent', 'true');
          }
        }
      });

      pr.on('paymentmethod', async (event: any) => {
        try {
          const userId = Cookies.get('userId');
      
          // Отправляем данные на сервер
          const subscriptionResponse = await fetch('https://golive.mobi/api/web/create-subscription/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              id: userId,
              payment_method_id: event.paymentMethod.id,
              payment_plan_id: payment_plan_id,
            }),
          });
      
          const subscriptionData = await subscriptionResponse.json();
      
          if (subscriptionResponse.ok) {
            event.complete('success');
            logAmplitudeEvent('e.4.0.2 - Success paying subscribe', { component: 'e.4.0.2 - Success paying subscribe' });
            handleSuccessfulPayment();
          } else {
            event.complete('fail');
            console.error('Ошибка при обработке подписки:', subscriptionData.error);
            logAmplitudeEvent('e.4.0.1 - Error paying subscribe', { component: 'e.4.0.1 - Error paying subscribe' });
          }
        } catch (error) {
          console.error('Ошибка при обработке подписки:', error);
          logAmplitudeEvent('e.4.0.1 - Error paying subscribe', { component: 'e.4.0.1 - Error paying subscribe' });
          event.complete('fail');
        }
      });
    }
  }, [stripe, amount, label, payment_plan_id]);

  const handleSuccessfulPayment = async () => {
    const userId = Cookies.get('userId');

    console.log('Subscription successful for user', userId);
    localStorage.setItem('randomCalls', (0).toString());
    localStorage.setItem('paymaster', 'ja-s');

    const amountInDollars = amount / 100;

    if (window.fbq) {
      window.fbq('track', 'Purchase', {
        value: amountInDollars.toFixed(2),
        currency: 'USD',
        event_id: purchasef,
      });
    }

    if (window.gtag) {
      window.gtag('event', 'purchase', {
        send_to: 'AW-16532794171',
        value: amountInDollars,
        currency: 'USD',
        transaction_id: userId,
      });
    }

    onPaymentSuccess();
  };

  return (
    <>
      {canMakePayment && paymentRequest && (
        <PaymentRequestButtonElement
          onClick={handlePaymentButtonClick}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                theme: 'dark',
                height: '64px',
              },
            },
          }}
        />
      )}
    </>
  );
};

export default PaymentModal;