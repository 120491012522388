import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  perspective: 1000px;
  overflow: hidden;
`;

const Card = styled.div`
  position: absolute;
  width: 40%;
  height: 70%;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform-style: preserve-3d;
  transition: transform 1s ease, opacity 1s ease, z-index 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const NameAge = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const Location = styled.div`
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Flag = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 50px;
`;

const LoadingMessage = styled.div`
  color: white;
  font-size: 1.5rem;
  text-align: center;
`;

interface CardData {
  number: number;
  name: string;
  age: number;
  country: string;
  countryName: string;
  city: string;
  image: string;
}

interface CarouselProps {
  mode: 'production' | 'preload';
}

const Carousel: React.FC<CarouselProps> = ({ mode }) => {
  const [images, setImages] = useState<CardData[]>([]);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);

  // Fetch data with caching
  const fetchData = async () => {
    const cachedData = localStorage.getItem('carouselData');
    if (cachedData) {
      setImages(JSON.parse(cachedData));
      setLoading(false);
    } else {
      try {
        const userId = document.cookie
          .split('; ')
          .find(row => row.startsWith('userId='))
          ?.split('=')[1];

        const response = await axios.post(
          'https://golive.mobi/api/search/carousel/',
          { id: userId },
          {
            headers: {
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            },
          }
        );

        const formattedData = response.data.map((item: CardData) => ({
          ...item,
          image: `https://golive.mobi${item.image}`,
        }));

        // Cache data
        localStorage.setItem('carouselData', JSON.stringify(formattedData));
        setImages(formattedData);
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Preload images
  const preloadImages = (imageList: CardData[]) => {
    imageList.forEach(item => {
      const img = new Image();
      img.src = item.image;
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (mode === 'preload' && images.length > 0) {
      preloadImages(images); // Preload all images
    }
  }, [mode, images]);

  useEffect(() => {
    if (mode === 'production') {
      const interval = setInterval(() => {
        setCurrent(prev => (prev + 1) % images.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [images, mode]);

  if (loading) {
    return mode === 'production' ? (
      <LoadingMessage></LoadingMessage>
    ) : null;
  }

  if (mode === 'preload') {
    return null; // Don't render anything in preload mode
  }

  const calculateIndex = (index: number, length: number) => {
    return (index + length) % length;
  };

  const calculateTransform = (index: number, current: number, length: number) => {
    const offset = calculateIndex(index - current, length);

    if (offset === 0) {
      return { transform: 'translateZ(200px)', zIndex: 2 };
    }
    if (offset === length - 1) {
      return { transform: 'translateX(-150px)', zIndex: 1 };
    }
    if (offset === 1) {
      return { transform: 'translateX(150px)', zIndex: 1 };
    }
    return { transform: 'translateZ(-500px)', zIndex: 0 };
  };

  const calculateOpacity = (index: number, current: number, length: number) => {
    const offset = calculateIndex(index - current, length);
    return offset === 0 || offset === 1 || offset === length - 1 ? 1 : 0;
  };

  return (
    <CarouselContainer>
      {images.map((item, index) => (
        <Card
          key={item.number}
          style={{
            backgroundImage: `url(${item.image})`,
            ...calculateTransform(index, current, images.length),
            opacity: calculateOpacity(index, current, images.length),
          }}
        >
          <TextContainer>
            <NameAge>
              {item.name}, {item.age}
            </NameAge>
            <Location>
              <Flag src={`https://flagcdn.com/h80/${item.country}.jpg`} alt={item.countryName} />
              {item.countryName} • {item.city}
            </Location>
          </TextContainer>
        </Card>
      ))}
    </CarouselContainer>
  );
};

export default Carousel;