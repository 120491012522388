import React, { useEffect } from 'react';
import styled from 'styled-components';
import signapple from '../res/singapple.png'; // Импортируем изображение

// Интерфейс для данных, возвращаемых при авторизации через Apple
interface AppleSignInResponse {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    email?: string;
    name?: {
      firstName?: string;
      lastName?: string;
    };
  };
}

interface AppleSignInButtonProps {
  onSuccess: (response: AppleSignInResponse) => void; // Колбэк для передачи успешного ответа
  onError?: (error: Error) => void; // Колбэк для обработки ошибок (необязательный)
}

// Стили для обёртки, чтобы центрировать изображение и задать отступы
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0; /* Отступы сверху и снизу */
`;

// Стили для изображения, чтобы задать максимальные размеры
const SignInImage = styled.img`
  cursor: pointer;
  width: 300px; /* Вы можете изменить размер изображения здесь */
  height: auto;
  border-radius: 25px
`;

const AppleSignInButton: React.FC<AppleSignInButtonProps> = ({ onSuccess, onError }) => {
  useEffect(() => {
    // Инициализация Apple ID авторизации
    window.AppleID.auth.init({
      clientId: 'goliive.mobi', // Ваш client ID
      redirectURI: 'https://web.golive.mobi/sso/response', // URL обратного вызова
      state: 'state_parameter', // Строка для защиты от CSRF-атак
      usePopup: true // Используем popup для авторизации
    });
  }, []);

  const handleAppleSignIn = () => {
    // Запуск процесса авторизации через Apple
    window.AppleID.auth.signIn()
      .then((response: AppleSignInResponse) => {
        const { authorization } = response;

        // Проверяем, что получили необходимые данные
        if (authorization && authorization.code && authorization.id_token) {
          console.log('Apple Sign-In response:', response);

          // Отправляем данные на бэкенд для обмена кодов на токены
          fetch('https://web.golive.mobi/sso/response', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              code: authorization.code,
              id_token: authorization.id_token,
              state: authorization.state,
            }),
          })
            .then(async (res) => {
              if (res.ok) {
                const data = await res.json();
                console.log('Backend Response:', data);
                onSuccess(response); // Вызываем onSuccess при успешной авторизации
              } else {
                console.error(`Backend Error: ${res.status} - ${res.statusText}`);
                const errorData = await res.text();
                console.error('Error details:', errorData);
                if (onError) onError(new Error(errorData));
              }
            })
            .catch((err) => {
              console.error('Backend Request Failed:', err);
              if (onError) onError(err); // Вызываем onError при ошибке
            });
        } else {
          const error = new Error('Apple Sign-In response does not contain required data.');
          console.error(error.message);
          if (onError) onError(error);
        }
      })
      .catch((error: Error) => {
        // Обработка ошибок при авторизации через Apple
        console.error('Apple Sign-In Error:', error);
        if (onError) onError(error);
      });
  };

  return (
    <ButtonWrapper>
      <SignInImage src={signapple} alt="Sign in with Apple" onClick={handleAppleSignIn} />
    </ButtonWrapper>
  );
};

export default AppleSignInButton;