import React, { useState, useEffect, ReactNode, useRef  } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import ProfileIcon from '../res/avatar.webp';
import CoinIcon from '../res/coin.webp';
import AvStoreIcon from '../res/av_store.webp';
import storeIcon from '../res/lownavbar/store.webp';
import listIcon from '../res/lownavbar/list.webp';
import videochatIcon from '../res/lownavbar/videochat.webp';
import textchatIcon from '../res/lownavbar/textchat.webp';
import Profile from './Profile';
import ModalStore from './ModalStore'; 
import CenterModal from './CenterModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentModal from '../components/Stripe';
import { logAmplitudeEvent } from '../amplitude';

interface StoreProps {
  onVideoChatClick: () => void; 
  onStoreClick: () => void;
  onListClick: () => void; 
  onTextChatClick: () => void;
}

interface ModalProps {
  onClose: () => void;
  balance: number;
  amount: number;
  paymentChargeId: number;  // Добавлено свойство paymentChargeId
  children: ReactNode;
  onPaymentSuccess: () => void;
}

interface CardData {
  amount: number;
  price: string;
  payment_charge_id: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  box-sizing: border-box;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const MarketText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const CoinsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CoinText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
`;

const CoinImage = styled.img`
  width: 30px;
  height: 30px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: calc(50% - 10px); // Две карточки в ряд на любых экранах

  @media (max-width: 330px) {
    width: 100%; // На очень узких экранах (менее 330px) одна карточка в ряд
  }
`;

const Card = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 170px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.bgColor || '#fff'};
  position: relative;
  
  @media (max-width: 768px) {
    height: 150px;
    padding: 15px;
  }
  
  @media (max-width: 480px) {
    height: 130px;
    padding: 10px;
  }
`;

const CoinIconImage = styled.img`
  width: 24px;
  height: 24px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
  }
`;

const CoinAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const CoinAmountContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const CoinAmount = styled.span`
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const CoinAmount2 = styled.span`
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const Price = styled.span`
  font-size: 16px;
  color: #888;
  position: absolute;
  bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const OldPrice = styled.span`
  font-size: 14px;
  color: #f00;
  text-decoration: line-through;
  position: absolute;
  bottom: 30px;
`;

const DiscountBadge = styled.div<{ bgColor?: string; fontSize?: string; padding?: string }>`
  background-color: ${props => props.bgColor || '#6c63ff'};
  color: #fff;
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  padding: ${props => props.padding || '3px 8px'};
  border-radius: 10px;
  position: absolute;
  top: 10px;
`;

const PopularText = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #6c63ff;
  margin-top: 10px;
  margin-bottom: -45px;
  position: absolute;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
`;

const NavItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const AvStoreImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const ScrollSpacer = styled.div`
height: 100px;
`;

const PaymentContainer = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Расположить кнопку сверху
  left: 0;             // Привязать к левой стороне
  width: 100%;         // Занимает всю ширину карточки
  height: 100%;        // Занимает всю высоту карточки
  display: flex;       // Для центрирования содержимого
  flex-direction: column; // Элементы будут располагаться по вертикали
  justify-content: center;
  align-items: center;
  z-index: 10;         // Поверх всех остальных элементов карточки
  cursor: pointer;     // Указатель на кнопку
`;


const Store: React.FC<StoreProps> = ({ onVideoChatClick, onStoreClick, onListClick, onTextChatClick }) => {
  const [userId, setUserId] = useState<string>('');
  const [balance, setBalance] = useState<number | null>(null);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CardData | null>(null); // Тип CardData | null
  const [isCenterModalOpen, setCenterModalOpen] = useState(false); // Состояние для показа модального окна
  const [purchasedCoins, setPurchasedCoins] = useState<number | null>(null);
  const paymentButtonRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    logAmplitudeEvent('e.2.0 - Store Screen', { component: 'e.2.0 - Store Screen' });
  }, []);

  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm'))

  const handlePaymentSuccess = () => {
    console.log('Payment successful');
  };

  // Определение, мобильная ли платформа
  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) return true;
    if (/iPad|iPhone|iPod/.test(userAgent)) return true;
    return false;
  };

  const isMobile = isMobilePlatform();
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  useEffect(() => {
    // Callback для обработки изменений в DOM
    const mutationCallback = (mutationsList: MutationRecord[]) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Ищем элемент с классом .GooglePayButton
          const googlePayButton = document.querySelector('.GooglePayButton');
          if (googlePayButton) {
            (googlePayButton as HTMLElement).style.maxHeight = '128px'; // Принудительно устанавливаем max-height
            observer.disconnect(); // Как только элемент найден, отключаем MutationObserver
          }
        }
      }
    };

    // Настраиваем MutationObserver для отслеживания изменений в body
    const observer = new MutationObserver(mutationCallback);
    observer.observe(document.body, { childList: true, subtree: true });

    // Очищаем observer при размонтировании компонента
    return () => observer.disconnect();
  }, []); 

  // Функция для запроса баланса
  const fetchBalance = (userId: string) => {
    fetch('https://golive.mobi/api/coin/status/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          setBalance(data.balance);
          console.log('wr l st')
          localStorage.setItem('balance', data.balance);
        }
      })
      .catch(error => console.error('Error fetching balance:', error));
  };

  // Эффект для установки userId и периодического запроса баланса
  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    if (userIdFromCookie && userId !== userIdFromCookie) {
      setUserId(userIdFromCookie);
    }

    if (userIdFromCookie) {
      fetchBalance(userIdFromCookie);
      const intervalId = setInterval(() => fetchBalance(userIdFromCookie), 3000);
      return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
    }
  }, [userId]);

  // Эффект для загрузки данных профиля
  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    if (userIdFromCookie && !profileData) {
      fetch('https://golive.mobi/api/user/status/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userIdFromCookie }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.id) {
            setProfileData(data);
            localStorage.setItem('profileData', JSON.stringify(data));
          }
        })
        .catch(error => console.error('Error fetching profile data:', error));
    } else {
      const savedProfileData = localStorage.getItem('profileData');
      if (savedProfileData && !profileData) {
        setProfileData(JSON.parse(savedProfileData));
      }
    }
  }, [profileData]);

  if (showProfile && profileData) {
    return <Profile onBackClick={() => setShowProfile(false)} profileData={profileData} />;
  }

  // Функция для обработки клика по карточке и открытия модального окна
  const handleCardClick = (cardData: CardData) => {
    setSelectedCard(cardData);
    setModalOpen(true);
  };

  // Функция для закрытия модального окна
  const closeModal = () => {
    setModalOpen(false);
  };

  const closeCenterModal = () => {
    setCenterModalOpen(false);
  };

  const handlePaymentSuccessAndClose = () => {
    if (selectedCard) {
      setPurchasedCoins(selectedCard.amount);  // Сохраняем количество купленных монет
      setModalOpen(false);       // Закрываем ModalStore
      setCenterModalOpen(true);  // Открываем CenterModal
      logAmplitudeEvent('e.4.0 - Selected card for pay', { component: 'e.4.0 - Selected card for pay' });
    }
  };

  const handleCardClickRef = (cardData: CardData) => {
    setSelectedCard(cardData); // Устанавливаем выбранную карточку
    console.log('press pay card')
    // Эмулируем клик по кнопке Stripe
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click(); // Программный клик по кнопке Stripe
    }
  };





  return (
    <Container>
      <TopBar>
        <ProfileContainer>
          <AvStoreImage src={AvStoreIcon} alt="Avatar Store" onClick={() => setShowProfile(true)} />
        </ProfileContainer>
        <CenterContainer>
          <MarketText>Market</MarketText>
        </CenterContainer>
        <CoinsContainer>
          <CoinText>{balance !== null ? balance : 'Loading...'}</CoinText>
          <CoinImage src={CoinIcon} alt="Coins" />
        </CoinsContainer>
      </TopBar>
      <br />
      <CardsContainer>

      <CardWrapper>
  <Card>
    <CoinAmountContainer>
      <CoinIconImage src={CoinIcon} alt="Coin" />
      <CoinAmount>150</CoinAmount>
    </CoinAmountContainer>
    <DiscountBadge style={{ visibility: 'hidden' }}>Save 34%</DiscountBadge>
    <Price>$2.99</Price>

    {/* Контейнер для Stripe, который скрыт и эмулирует клик */}
    <PaymentContainer  ref={paymentButtonRef}>
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`150 Coins`}
          amount={299} // Преобразование суммы в центы ($2.99)
          payment_charge_id={3} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
   
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`150 Coins`}
          amount={299} // Преобразование суммы в центы ($2.99)
          payment_charge_id={3} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>

    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`150 Coins`}
          amount={299} // Преобразование суммы в центы ($2.99)
          payment_charge_id={3} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
    </PaymentContainer>
  </Card>
</CardWrapper>


        <CardWrapper>
          <Card>
            <CoinAmountContainer>
              <CoinIconImage src={CoinIcon} alt="Coin" />
              <CoinAmount>400</CoinAmount>
            </CoinAmountContainer>
            <DiscountBadge>Save 34%</DiscountBadge>
            <Price>$4.99</Price>

 {/* Контейнер для Stripe, который скрыт и эмулирует клик */}
 <PaymentContainer  ref={paymentButtonRef}>
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`400 Coins`}
          amount={499} // Преобразование суммы в центы ($2.99)
          payment_charge_id={2} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
   
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`400 Coins`}
          amount={499} // Преобразование суммы в центы ($2.99)
          payment_charge_id={2} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>

    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`400 Coins`}
          amount={499} // Преобразование суммы в центы ($2.99)
          payment_charge_id={2} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
    </PaymentContainer>

          </Card>
        </CardWrapper>

        <CardWrapper>
          <Card>
            <CoinAmountContainer>
              <CoinIconImage src={CoinIcon} alt="Coin" />
              <CoinAmount>900</CoinAmount>
            </CoinAmountContainer>
            <DiscountBadge>Save 39%</DiscountBadge>
            <Price>$9.99</Price>

{/* Контейнер для Stripe, который скрыт и эмулирует клик */}
<PaymentContainer  ref={paymentButtonRef}>
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`900 Coins`}
          amount={999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={4} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
   
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`900 Coins`}
          amount={999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={4} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>

    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`900 Coins`}
          amount={999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={4} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
    </PaymentContainer>

          </Card>
        </CardWrapper>

        <CardWrapper>
          <Card bgColor="#fcd900">
            <CoinAmountContainer2>
              <CoinIconImage src={CoinIcon} alt="Coin" />
              <CoinAmount2>1600</CoinAmount2>
            </CoinAmountContainer2>
            <PopularText>Most Popular</PopularText>
            <DiscountBadge>Save 45%</DiscountBadge>
            <Price>$14.99</Price>

{/* Контейнер для Stripe, который скрыт и эмулирует клик */}
<PaymentContainer  ref={paymentButtonRef}>
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`1600 Coins`}
          amount={1499} // Преобразование суммы в центы ($2.99)
          payment_charge_id={5} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
   
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`1600 Coins`}
          amount={1499} // Преобразование суммы в центы ($2.99)
          payment_charge_id={5} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>

    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`1600 Coins`}
          amount={1499} // Преобразование суммы в центы ($2.99)
          payment_charge_id={5} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
    </PaymentContainer>

          </Card>
        </CardWrapper>

        <CardWrapper>
          <Card>
            <CoinAmountContainer>
              <CoinIconImage src={CoinIcon} alt="Coin" />
              <CoinAmount>2100</CoinAmount>
            </CoinAmountContainer>
            <DiscountBadge bgColor="#ffcc00" fontSize="14px" padding="5px 10px">Sale 46%</DiscountBadge>
            <OldPrice>$35.99</OldPrice>
            <Price>$19.99</Price>

{/* Контейнер для Stripe, который скрыт и эмулирует клик */}
<PaymentContainer  ref={paymentButtonRef}>
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`2100 Coins`}
          amount={1999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={6} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
   
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`2100 Coins`}
          amount={1999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={6} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>

    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`2100 Coins`}
          amount={1999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={6} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
    </PaymentContainer>

          </Card>
        </CardWrapper>

        <CardWrapper>
         {/* <Card onClick={() => handleCardClick({ amount: 5500, price: '$49.99', payment_charge_id: 7 })}> */}
         <Card>
            <CoinAmountContainer>
              <CoinIconImage src={CoinIcon} alt="Coin" />
              <CoinAmount>5500</CoinAmount>
            </CoinAmountContainer>
            <DiscountBadge bgColor="#ffcc00" fontSize="14px" padding="5px 10px">Sale 56%</DiscountBadge>
            <OldPrice>$110.99</OldPrice>
            <Price>$49.99</Price>

{/* Контейнер для Stripe, который скрыт и эмулирует клик */}
<PaymentContainer  ref={paymentButtonRef}>
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`5500 Coins`}
          amount={4999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={7} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
   
    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`5500 Coins`}
          amount={4999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={7} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>

    <div style={{ width: '120px', opacity: 0.01}}  >  <Elements  stripe={stripePromise}>
        <PaymentModal 
          label={`5500 Coins`}
          amount={4999} // Преобразование суммы в центы ($2.99)
          payment_charge_id={7} // Передаем payment_charge_id для оплаты
          onPaymentSuccess={handlePaymentSuccessAndClose} // Обработка успешного платежа
        />
      </Elements>
      </div>
    </PaymentContainer>

          </Card>
        </CardWrapper>
      </CardsContainer>
      
      <NavBar >
        <NavItem src={storeIcon} alt="Store" onClick={onStoreClick} />
        <NavItem src={listIcon} alt="List" onClick={onListClick} /> {/* Переключение в Feed */}
        <NavItem src={videochatIcon} alt="Video Chat" onClick={onVideoChatClick} />
        <NavItem src={textchatIcon} alt="Text Chat" onClick={onTextChatClick} />
      </NavBar>
      <ScrollSpacer />

      {isModalOpen && selectedCard && (
  <ModalStore 
    onClose={closeModal} 
    balance={balance || 0}
    amount={selectedCard.amount}
    paymentChargeId={selectedCard.payment_charge_id}  // Передаем payment_charge_id
    onPaymentSuccessAndClose={handlePaymentSuccessAndClose} // Передаем обработчик
  >
    <h3>Selected Package</h3>
    <p>Coin Amount: {selectedCard.amount}</p>
    <p>Price: {selectedCard.price}</p>
  </ModalStore>
)}

{isCenterModalOpen && (
  <CenterModal 
    onClose={closeCenterModal} 
    purchasedCoins={purchasedCoins || 0}  // Передаем количество купленных монет
  />
)}

    </Container>
  );
};

export default Store;