import React, { useState } from 'react';
import styled from 'styled-components';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Cookies from 'js-cookie';

const CardInputContainer = styled.div`
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  margin: 0 auto;
`;

const InputLabel = styled.label`
  font-size: 16.5px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  display: block;
`;

const CardElementContainer = styled.div<{ isValid: boolean }>`
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border: 1px solid ${({ isValid }) => (isValid ? '#ccc' : 'red')};
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;

  .StripeElement {
    background-color: transparent;
    font-size: 18px;
    color: ${({ isValid }) => (isValid ? '#000' : 'red')};
    text-align: center;
    font-family: inherit;

    &::placeholder {
      color: #ccc;
    }

    &:focus {
      outline: none;
    }
  }
`;

const PayButton = styled.button`
  margin-top: 15px;
  width: 300px; // Ширина кнопки
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 15px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
  display: block; // Центрирование кнопки

  &:disabled {
    background-color: #ccc;
    color: gray;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

interface PaymentCardFormProps {
  paymentPlanId: number; // ID плана подписки
  onPaymentSuccess: () => void; // Callback при успешной оплате
}

const PaymentCardForm: React.FC<PaymentCardFormProps> = ({ paymentPlanId, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardValid, setIsCardValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handlePayment = async () => {
    if (!stripe || !elements) {
      setErrorMessage('Stripe is not loaded');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setErrorMessage('CardElement not found');
      setIsProcessing(false);
      return;
    }

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setErrorMessage(error.message || 'Ошибка при создании платежного метода');
        setIsProcessing(false);
        return;
      }

      const userId = Cookies.get('userId');
      if (!userId) {
        setErrorMessage('User ID не найден');
        setIsProcessing(false);
        return;
      }

      // Отправляем данные на сервер
      const response = await fetch('https://golive.mobi/api/web/create-subscription/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          id: userId,
          payment_method_id: paymentMethod?.id,
          payment_plan_id: paymentPlanId,
        }),
      });

      if (!response.ok) {
        throw new Error('Error during payment');
      }

      onPaymentSuccess(); // Успех
    } catch (err) {
      setErrorMessage((err as Error).message || 'Ошибка при оплате');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
    <CardInputContainer>
      <InputLabel>Card Details</InputLabel>
      <CardElementContainer isValid={isCardValid}>
      <CardElement
  options={{
    style: {
      base: {
        fontSize: '18px',
        color: isCardValid ? '#000' : 'red',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: 'red',
      },
    },
    hidePostalCode: true, // Скрываем поле для ввода почтового индекса
  }}
  onChange={(event) => setIsCardValid(event.complete && !event.error)}
/>
      </CardElementContainer>



      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </CardInputContainer>

<PayButton onClick={handlePayment} disabled={!isCardValid || isProcessing}>
{isProcessing ? 'Processing...' : 'Pay'}
</PayButton>

</>);
};

export default PaymentCardForm;