import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import storeIcon from '../res/lownavbar/store.webp';
import listIcon from '../res/lownavbar/list.webp';
import videochatIcon from '../res/lownavbar/videochat.webp';
import textchatIcon from '../res/lownavbar/textchat.webp';
import feedcamera from '../res/feedcamera.png';
import feedmessage from '../res/feedmessage.png';
import InChat from './InChat';
import WaitPrivateCall from './WaitPrivateCall';
import { Elements } from '@stripe/react-stripe-js'; // Импортируем Elements из Stripe
import { loadStripe } from '@stripe/stripe-js'; // Импортируем loadStripe
import Stripe from '../components/Stripe'; // Импортируем твой компонент PaymentModal
import StripeReady from './StripeReady'
import popupclosebutton from '../res/popupclosebutton.webp';
import { logAmplitudeEvent } from '../amplitude';
import startvip from '../res/firsttopupv2.png';
import topup from '../res/topup.webp'
import payingcard1 from '../res/payingcard1.webp'
import payingcard2 from '../res/payingcard2.webp'
import StripeSubscribe from '../components/StripeSubscribe';

// button for feed
import all_on from '../button_feed/all_on.png'
import all_off from '../button_feed/all_off.png'
import hot_off from '../button_feed/hot_off.png'
import hot_on from '../button_feed/hot_on.png'
import new_off from '../button_feed/new_off.png'
import new_on from '../button_feed/new_on.png'
import online_off from '../button_feed/online_off.png'
import online_on from '../button_feed/online_on.png'
// button for feed

interface FeedProps {
  onStoreClick: () => void; 
  onListClick: () => void;
  onVideoChatClick: () => void;
  onTextChatClick: () => void;
  onChatClick: (chat: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  box-sizing: border-box;
  padding-bottom: 80px;
`;

const TabBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const TabButton = styled.button<{ active: boolean }>`
  font-size: 18px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => (props.active ? '#000' : '#888')};
  background-color: white;
  border: none;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    display: ${props => (props.active ? 'block' : 'none')};
    width: 100%;
    height: 2px;
    background-color: #6c63ff;
    position: absolute;
    bottom: -4px;
    left: 0;
  }
`;

const FilterBar = styled.div`
  display: flex;
  width: 100%;

`;



const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

const Card = styled.div`
  position: relative;
  width: calc(50% - 10px);
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
  color: #fff;
`;

const SmallAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  padding: 2px;
  background: linear-gradient(45deg, #ff4b2b, #ff416c);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
`;

const StatusDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 5px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
`;

const NavItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const IconButton = styled.img`
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  animation: shake 4s ease-in-out infinite;

  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    3% {
      transform: rotate(5deg);
    }
    6% {
      transform: rotate(-10deg);
    }
    9% {
      transform: rotate(10deg);
    }
    12% {
      transform: rotate(-5deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg); /* Пауза до следующего цикла */
    }
  }
`;

// Стилизованный компонент для модального окна с затемнением фона
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Затемнение фона
  display: flex;
  justify-content: center;
  align-items: flex-end; // Прижимаем модальное окно к низу
  z-index: 1000; // Высокий z-index для перекрытия всех элементов
`;

const ModalContent = styled.div`
  position: relative;
  width: 95%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const ModalContentSecond = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 15px 0; // Отступ сверху для кнопки и текста
  width: 100%;
`;

const CloseButton = styled.img`
  position: absolute;
  left: 20px; // Позиционирование кнопки от левого края
  top: 15px; // Позиционирование кнопки от верха
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const WelcomeText = styled.div`
  font-size: 25px;
  color: #FFFFFF; 
  font-weight: bold;
  margin-top: 5px; // Отступ от верха на том же уровне, что и у CloseButton
  text-align: center;
  flex-grow: 1; // Заполняет оставшееся пространство для центрирования текста
`;

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.1); // Цвет с прозрачностью 10%
  padding: 15px 25px;
  border-radius: 20px;
  max-width: 200px; // Уменьшенная ширина контейнера
  margin-left: auto;
  margin-right: auto;
`;

const CountdownText = styled.div`
  font-size: 16px;
  color: #FFFFFF; // Белый цвет без прозрачности для текста "Countdown"
  margin-bottom: 5px;
`;

const Timer = styled.div`
  display: flex;
  align-items: center;
`;

const Time = styled.div<{ isHighlighted?: boolean }>`
  font-size: 24px;
  color: ${({ isHighlighted }) => (isHighlighted ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)')};
  font-weight: bold;
`;

const Separator = styled.div`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.4); // Белый с прозрачностью 40% для разделителей
  font-weight: bold;
  margin: 0 5px;
`;

const StartVipImage = styled.img`
 // margin-top: 20px;
  width: 350px; // Увеличенная ширина изображения
  height: auto; // Автоматическая высота, чтобы сохранить пропорции
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const ContinueVipButton = styled.button`
  margin-top: 20px;
  width: 300px; // Ширина кнопки
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 15px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
  display: block; // Центрирование кнопки
`;

const AgreementText = styled.div`
  margin-top: 10px;  // Отступ сверху от кнопки "Continue"
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5); // Белый цвет с прозрачностью 50%
  text-align: center;
  // max-width: 300px; // Ширина текста, такая же как у кнопки "Continue"
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;  // Отступ снизу до края модального окна

  a {
    color: rgba(255, 255, 255, 0.5);  // Сохраняем цвет текста
    text-decoration: underline;  // Добавляем подчеркивание для ссылок
    cursor: pointer;

    &:hover {
      text-decoration: none;  // Убираем подчеркивание при наведении
    }
  }
`;

const PaymentContainer = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Верх кнопки
  left: 0;             // Левый край кнопки
  width: 100%;         // Ширина кнопки
  height: 100%;        // Высота кнопки
  display: flex;       // Для выравнивания содержимого
  justify-content: center;
  align-items: center;
  z-index: 10;         // Расположить контейнер выше кнопки по z-индексу
`;

const PaymentContainerV = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Верх кнопки
  left: 0;             // Левый край кнопки
  width: 100%;         // Ширина кнопки
  height: 100%;        // Высота кнопки
  display: flex;       // Для выравнивания содержимого
  flex-direction: column; // Элементы будут располагаться по вертикали
  justify-content: center;
  align-items: center;
  z-index: 10;         // Расположить контейнер выше кнопки по z-индексу
`;

const FilterButton1 = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px; /* Размер кнопки */
  height: 50px;
  transition: transform 0.2s ease-in-out;

  img {
    width: 100%;
    height: auto;
  }
`;

const FilterButton2 = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px; /* Размер кнопки */
  height: 50px;
  transition: transform 0.2s ease-in-out;

  img {
    width: 100%;
    height: auto;
  }
`;

const FilterButton3 = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px; /* Размер кнопки */
  height: 50px;
  transition: transform 0.2s ease-in-out;

  img {
    width: 100%;
    height: auto;
  }
`;

const FilterButton4 = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px; /* Размер кнопки */
  height: 50px;
  transition: transform 0.2s ease-in-out;

  img {
    width: 100%;
    height: auto;
  }
`;

const Feed: React.FC<FeedProps> = ({ onStoreClick, onListClick, onVideoChatClick, onTextChatClick }) => {
  const [activeTab, setActiveTab] = useState<'Explore' | 'Followed'>('Explore');
  const [activeFilter, setActiveFilter] = useState<'Hot' | 'Online' | 'New' | 'All'>('Hot');
  const [profiles, setProfiles] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [selectedChat, setSelectedChat] = useState<any | null>(null);
  const [waitingForPrivateCall, setWaitingForPrivateCall] = useState<any | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalTopup, setIsModalTopup] = useState(false)
  const [purchasedCoinsonboard, setPurchasedCoinsonboard] = useState<number>(100);
  const stripePromise = loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm');
  const [reloadProfiles, setReloadProfiles] = useState(false);

  const filters = [
    { type: 'Hot', onImage: hot_on, offImage: hot_off },
    { type: 'Online', onImage: online_on, offImage: online_off },
    { type: 'New', onImage: new_on, offImage: new_off },
    { type: 'All', onImage: all_on, offImage: all_off },
  ];

  useEffect(() => {
    logAmplitudeEvent('e.2.0 - Feed Screen', { component: 'e.2.0 - Feed Screen' });
  }, []);

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  const handlePaymentSuccessStripe = () => {
    console.log('success pay in stripe');
    logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
    localStorage.setItem('DBB98', '9367');
    localStorage.setItem('paymaster', 'ja-s')
    // Закрываем все модальные окна
    setIsModalOpen(false); 

  };

  const loadMoreProfiles = async (startPage: number, endPage: number) => {
    const userIdFromCookie = Cookies.get('userId');
    if (!userIdFromCookie || loading) return;
    setLoading(true);
    try {
      const loadedProfiles: any[] = [];
      for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
        const response = await axios.post(
          'https://golive.mobi/api/feed/list/',
          {
            id: userIdFromCookie,
            extra: 'all',
            with_bot: 1,
            page: pageNum,
          },
          {
            headers: {
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            },
          }
        );
        loadedProfiles.push(...response.data.list);
      }
      setProfiles((prevProfiles) => [...prevProfiles, ...loadedProfiles]);
      setPage(endPage);
      setIsLoadingMore(false);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching more profiles:', error);
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    const loadProfiles = async (pageNum: number) => {
      const userIdFromCookie = Cookies.get('userId');
      if (!userIdFromCookie || pageNum > 10) return;  // Останавливаем загрузку на 10 странице

      try {
        // Проверяем значение paymaster в localStorage
        const paymasterStatus = localStorage.getItem('paymaster');
        
        // Устанавливаем with_bot только если paymaster не "ja-s"
        const withBot = paymasterStatus === 'nein-s' ? 1 : undefined;

        const response = await axios.post(
          'https://golive.mobi/api/feed/list/',
          {
            id: userIdFromCookie,
            extra: 'all',
            page: pageNum,
            ...(withBot !== undefined && { with_bot: withBot }) // Добавляем with_bot только если оно определено
          },
          {
            headers: {
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            }
          }
        );

        // Фильтруем профили: исключаем snooze: 1, если статус не offline
        const filteredProfiles = response.data.list.filter((profile: any) => {
          return !(profile.snooze === 1 && profile.status !== "offline");
        });

        setProfiles((prevProfiles) => [...prevProfiles, ...filteredProfiles]);  // Добавляем отфильтрованные профили

        if (response.data.list.length > 0 && pageNum < 10) {
          loadProfiles(pageNum + 1);  // Загружаем следующую страницу, если есть данные и еще не достигли 10 страниц
        }
      } catch (error) {
        console.error('Error fetching feed:', error);
      }
    };

    loadProfiles(1);  // Начинаем с первой страницы при монтировании компонента
  }, [reloadProfiles]);

 /* useEffect(() => {
    const handleScroll = () => {
      if (loaderRef.current && !loading && !isLoadingMore) {
        const { bottom } = loaderRef.current.getBoundingClientRect();
        if (bottom <= window.innerHeight) {
          setIsLoadingMore(true);
          loadMoreProfiles(page + 1, page + 5); // Загружаем еще 5 страниц при скролле
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, isLoadingMore, page]);*/

  const handleClose = () => {
    localStorage.setItem('DBB98', '9367');
    setIsModalOpen(false);
    setIsModalTopup(false);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'online':
      case 'online_background':
        return 'green';
      case 'busy':
        return 'red';
      default:
        return 'gray';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'online':
      case 'online_background':
        return 'Available';
      case 'busy':
        return 'In call';
      default:
        return 'Recently active';
    }
  };

  const getIconForStatus = (status: string) => {
    if (status === 'online' || status === 'online_background') {
      return feedcamera;
    } else {
      return feedmessage;
    }
  };

  {/* const handleIconClick = (profile: any) => {
    const paymasterStatus = localStorage.getItem('paymaster');
  
    if (paymasterStatus === 'nein-s') {
      // Если paymaster равен 'nein-s', открываем модальное окно
      setIsModalOpen(true);
    } else if (paymasterStatus === 'ja-s') {
      // Если paymaster равен 'ja-s', устанавливаем состояние для звонка
      if (profile.status === 'online' || profile.status === 'online_background') {
        setWaitingForPrivateCall({
          id: profile.id,
          name: profile.name,
          avatar: profile.avatar,
          country: profile.countryCode,  // Передаем countryCode
          age: profile.age,              // Передаем age
          flag: profile.flag             // Передаем flag
        });
      } else {
        setSelectedChat(profile);
      }
    }
  }; */}




  const fetchBalance = (userId: string) => {
    fetch('https://golive.mobi/api/coin/status/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
       
          // Сохраняем баланс в localStorage
          localStorage.setItem('balance', data.balance.toString());
          console.log('Balance updated');
        }
      })
      .catch(error => console.error('Error fetching balance:', error));
  };

  useEffect(() => {
    const userId = Cookies.get('userId');
    if (!userId) return;

    // Запускаем интервал для обновления баланса каждые 1.5 секунды
    const intervalId = setInterval(() => {
      fetchBalance(userId);
    }, 1500);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  const handleIconClick = (profile: any) => {
    const paymasterStatus = localStorage.getItem('paymaster');
    
    if (getIconForStatus(profile.status) === feedmessage) {
      setSelectedChat(profile);
      return;
    }

    if (paymasterStatus === 'nein-s') {
      // Если paymaster равен 'nein-s', открываем модальное окно
      setIsModalOpen(true);
     //setIsModalTopup(true);
    } else if (paymasterStatus === 'ja-s') {
      // Проверяем баланс
      const balance = parseInt(localStorage.getItem('balance') || '0', 10);
  
      if (balance < 120) {
        // Если баланс меньше 120, открываем модальное окно для пополнения
        setIsModalTopup(true);
      } else {
        // Если баланс достаточный, открываем звонок или чат
        if (profile.status === 'online' || profile.status === 'online_background') {
          setWaitingForPrivateCall({
            id: profile.id,
            name: profile.name,
            avatar: profile.avatar,
            country: profile.countryCode,
            age: profile.age,
            flag: profile.flag
          });
        } else {
          setSelectedChat(profile);
        }
      }
    }
  };

  if (waitingForPrivateCall) {
    return (
      <WaitPrivateCall 
        userData={waitingForPrivateCall}
        onCancel={() => setWaitingForPrivateCall(null)} 
      />
    );
  }

  if (selectedChat) {
    return (
      <InChat
        chat={selectedChat}
        onBack={() => setSelectedChat(null)}
      />
    );
  }

  const handlePaymentSuccessPay2 = () => {
    setPurchasedCoinsonboard(900);
    logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
    localStorage.setItem('DBB98', '9367');
    
    // Закрываем все модальные окна
    setReloadProfiles((prev) => !prev);
    setIsModalTopup(false); 

  };

  const handlePaymentSuccessPay1 = () => {
    setPurchasedCoinsonboard(400);
    logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
    localStorage.setItem('DBB98', '9367');
    
    setReloadProfiles((prev) => !prev);
    setIsModalTopup(false); 
    
  };

  return (
    <>
      <Container>
   {/*     <TabBar>
          <TabButton active={activeTab === 'Explore'} onClick={() => setActiveTab('Explore')}>
            Explore
          </TabButton>
          <TabButton active={activeTab === 'Followed'} onClick={() => setActiveTab('Followed')}>
            Followed
          </TabButton> 
        </TabBar> */}

<FilterBar>
      {/* Hot */}
      <FilterButton1 onClick={() => setActiveFilter('Hot')}>
        <img
          src={activeFilter === 'Hot' ? hot_on : hot_off}
          alt="Hot"
        />
      </FilterButton1>

      {/* Online */}
      <FilterButton2 onClick={() => setActiveFilter('Online')}>
        <img
          src={activeFilter === 'Online' ? online_on : online_off}
          alt="Online"
        />
      </FilterButton2>

      {/* New */}
      <FilterButton3 onClick={() => setActiveFilter('New')}>
        <img
          src={activeFilter === 'New' ? new_on : new_off}
          alt="New"
        />
      </FilterButton3>

      {/* All */}
      <FilterButton4 onClick={() => setActiveFilter('All')}>
        <img
          src={activeFilter === 'All' ? all_on : all_off}
          alt="All"
        />
      </FilterButton4>
    </FilterBar>

        <CardsContainer>
          {profiles.map((profile, index) => (
            <Card key={index}>
              <AvatarImage src={`https://golive.mobi${profile.cover || profile.avatar}`}  alt="Avatar" />
              <Overlay>
              {/*  <SmallAvatar src={`https://golive.mobi${profile.avatar}`} alt="Avatar" /> */}
                <TextContainer>
                  <Status>
                    <StatusDot color={getStatusColor(profile.status)} />
                    {getStatusText(profile.status)}
                  </Status>
                  <Name>{profile.name}</Name>
                </TextContainer>
              </Overlay>
              <IconButton
                src={getIconForStatus(profile.status)}
                alt="Icon"
                onClick={() => handleIconClick(profile)}
              />
            </Card>
          ))}
        </CardsContainer>
        <div ref={loaderRef} style={{ height: '20px' }} />
      </Container>
      <NavBar style={containerStyle}>
        <NavItem src={storeIcon} alt="Store" onClick={onStoreClick} />
        <NavItem src={listIcon} alt="List" onClick={onListClick} />
        <NavItem src={videochatIcon} alt="Video Chat" onClick={onVideoChatClick} />
        <NavItem src={textchatIcon} alt="Text Chat" onClick={onTextChatClick} />
      </NavBar>


      {isModalOpen && (
    <ModalOverlay style={containerStyle}>
      <ModalContent>
        <HeaderContainer>
          <CloseButton 
            src={popupclosebutton} 
            alt="Close" 
            onClick={handleClose} 
          />
          {/*<WelcomeText>Welcome bonus</WelcomeText>*/}
        </HeaderContainer>


        <StartVipImage src={startvip} alt="Start VIP" />

        <div style={{ position: 'relative' }}>
{/* Кнопка Continue */}
<ContinueVipButton>Continue</ContinueVipButton>

{/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
<PaymentContainer>
<div style={{ width: '300px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <StripeSubscribe
    label={`120 Coins.`}
    amount={100} 
    payment_plan_id={3} 
    onPaymentSuccess={handlePaymentSuccessStripe}
  />
</Elements>
</div>
</PaymentContainer>
</div>

     {/*   <Stripe onPaymentSuccess={handlePaymentSuccess} /> */}

        <AgreementText>
By purchasing you agree to our 
<a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> and 
<a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</AgreementText>
      </ModalContent>
    </ModalOverlay>
  )}

  { isModalTopup && (
    <ModalOverlay style={containerStyle}>
    <ModalContent style={{ backgroundColor: '#6D33FF', padding: '30px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
      {/* Изображение Topup прижато к верху и выровнено по центру */}
      <img 
        src={topup} 
        alt="Top Up" 
        style={{ width: '100%', height: 'auto', marginBottom: '20px', objectFit: 'contain' }} 
      />

<div 
  style={{
    display: 'flex',
    justifyContent: 'center', // Центрируем контейнер по горизонтали
    width: '100%', // Контейнер занимает всю ширину
  }}
>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px', // Расстояние между карточками
      maxWidth: '340px', // Фиксированная максимальная ширина для обеих карточек, чтобы оставались по центру
      flexWrap: 'wrap', // Позволяет карточкам переноситься на новую строку на малых экранах
    }}
  >

<div style={{ position: 'relative' }}>
{/* Кнопка Continue */}
<img 
        src={payingcard1} 
        alt="Paying Card 1" 
        style={{ 
          width: '150px', // Регулируемый размер изображения
          height: 'auto', // Сохранение пропорций
        }} 
  
      />

{/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
<PaymentContainerV>
<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <Stripe
    label={`400 Coins`}
    amount={245} // Преобразование суммы в центы
    payment_charge_id={3} // Передаем payment_charge_id
    onPaymentSuccess={handlePaymentSuccessPay1}
  />
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <Stripe
    label={`400 Coins`}
    amount={245} // Преобразование суммы в центы
    payment_charge_id={3} // Передаем payment_charge_id
    onPaymentSuccess={handlePaymentSuccessPay1}
  />
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <Stripe
    label={`400 Coins`}
    amount={245} // Преобразование суммы в центы
    payment_charge_id={3} // Передаем payment_charge_id
    onPaymentSuccess={handlePaymentSuccessPay1}
  />
</Elements>
</div>
</PaymentContainerV>
</div>



<div style={{ position: 'relative' }}>
{/* Кнопка Continue */}
<img 
        src={payingcard2} 
        alt="Paying Card 2" 
        style={{ 
          width: '150px', // Регулируемый размер изображения
          height: 'auto', // Сохранение пропорций
        }} 
   
      />

{/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
<PaymentContainerV>
<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <Stripe
    label={`900 Coins`}
    amount={499} // Преобразование суммы в центы
    payment_charge_id={2} // Передаем payment_charge_id
    onPaymentSuccess={handlePaymentSuccessPay2}
  />
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <Stripe
    label={`900 Coins`}
    amount={499} // Преобразование суммы в центы
    payment_charge_id={2} // Передаем payment_charge_id
    onPaymentSuccess={handlePaymentSuccessPay2}
  />
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
  <Stripe
    label={`900 Coins`}
    amount={499} // Преобразование суммы в центы
    payment_charge_id={2} // Передаем payment_charge_id
    onPaymentSuccess={handlePaymentSuccessPay2}
  />
</Elements>
</div>
</PaymentContainerV>
</div>
</div>
</div>

<ContinueVipButton onClick={handleClose}>Continue</ContinueVipButton>

    </ModalContent>
  </ModalOverlay>
  )}



    </>
  );


};

export default Feed;