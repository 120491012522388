import React, { useEffect, useRef, useState } from 'react';
import BytePlusRTC, {
  RoomProfileType,
  MediaType,
  IRTCEngine,
  StreamIndex,
  RemoteStreamStats,
} from '@byteplus/rtc';
import Cookies from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';
import EndCallIcon from '../res/endcallincall.png';
import AvatarIcon from '../res/avatar_temp.webp';
import TranslateIcon from '../res/translate.webp';
import DotsIcon from '../res/dotsthree.webp';
import GiftIcon from '../res/giftcall.webp';
import MicroIcon from '../res/micro.webp';
import TimeWatch from '../res/timewatch.webp';
import topupbalance from '../res/topupbalance.webp';
import topup1 from '../res/topup1.webp';
import topup2 from '../res/topup2.webp';
import * as faceapi from 'face-api.js';
import { logAmplitudeEvent } from '../amplitude';

interface RemoteVideoStats {
  width: number;
  height: number;
  videoLossRate: number;
  receivedKBitrate: number;
  decoderOutputFrameRate: number;
  stallCount?: number;
  stallDuration?: number;
  totalRtt: number;
  isScreen: boolean;
  statsInterval: number;
  rtt: number;
  codecType: 'H264' | 'VP8';
}


interface CallScreenProps {
  roomId: string;
  onClose: () => void;
  onStoreEndCall: () => void;
  onTimerEndOrContinue: () => void; // Добавляем этот пропс
  userData: {
    name: string;
    avatar: string;
  };
}



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 90vh;  /* Занимаем всю высоту экрана */
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
`;

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,26,95,1) 30%, rgba(40,26,95,1) 70%, rgba(0,0,0,1) 100%);
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const GradientBackground2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: grey;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 4vh); 
  padding: 0.5vh 2vh;
  position: absolute;
  top: 0;
  z-index: 2;
`;

const Icon = styled.img`
  width: 5vh;
  height: 5vh;
  cursor: pointer;
`;

const Avatar = styled.img`
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  margin-right: 0.5vh;
`;

const UserName = styled.div`
  color: white;
  font-size: 1.8vh;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  padding: 0.5vh 1vh;
  margin-left: 1vh;
  margin-right: 1vh;
  flex-grow: 1;
  min-width: 0;
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: calc(100% - 4vh); 
  padding: 1vh 2vh;
  position: absolute;
  bottom: 1vh;
  z-index: 2;
`;

const RoundButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6vh;
  height: 6vh;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6); 
  border-radius: 50%;
`;

const ButtonIcon = styled.img`
  width: 3vh;
  height: 3vh;
`;

const InputContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50px;
  padding: 0.5vh 2vh;
  margin: 0 1vh;
  height: 6vh;
`;

const PlaceholderInput = styled.input`
  width: 100%;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 2vh;
  outline: none;
`;

const BalanceButton = styled.button`
  width: 80%; // Увеличиваем ширину кнопки "Top up balance"
  background-color: #FCD900;
  color: black;
  font-size: 1.7vh;
  font-weight: 600;
  padding: 1.5vh 0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  bottom: 11vh;
  left: 10%; // Центрируем кнопку
`;

const BlueBlock = styled.div`
  width: 25%;
  background-color: #007BFF;
  padding: 11vh 2vh;
  border-radius: 25px;
  position: absolute;
  bottom: 20vh;
  right: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenteredTextContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: gray;
  text-align: center;
  margin-top: -15vh; /* Поднимите содержимое на 5vh */
`;

const Image2 = styled.img`
  width: 20vh; /* Размер картинки можно подкорректировать */
  height: auto;
  margin-bottom: 2vh;
`;

const MainText2 = styled.div`
  font-size: 4.5vh;
  color: white;
  font-weight: bold;
  margin-bottom: 1vh;
`;

const SubText2 = styled.div`
  font-size: 2.5vh;
  color: white;
`;

const LowBalanceOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Полупрозрачный черный фон */
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const OverlayContent = styled.div`
background-color: #6C4DF2;
border-radius: 20px;
padding: 20px;
text-align: center;
width: 90%;
max-width: 400px;
margin-top: auto;

`;

const OverlayTitle = styled.h2`
  color: #FFD700; /* Желтый цвет заголовка */
  font-size: 24px;
  margin-bottom: 10px;
`;

const OverlayText = styled.p`
  color: white;
  margin-bottom: 20px;
  font-size: 18px;
`;

const TimerText = styled.p`
  color: #FFD700; // Цвет таймера
  margin-top: -10px; // Корректируем отступ, чтобы таймер находился ближе к тексту
  font-size: 24px; // Размер шрифта для таймера
  font-weight: bold;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px; // Увеличиваем padding
  width: 45%;
  text-align: center;
  color: black;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Добавляем тень
`;

const CardTitle = styled.div`
  font-size: 24px; // Увеличиваем размер шрифта для заголовка
  font-weight: bold;
  margin-bottom: 5px;
`;

const CardSubtitle = styled.div`
  font-size: 16px; // Увеличиваем шрифт подзаголовка
  color: grey;
  margin-bottom: 10px;
`;

const CardPrice = styled.div`
  font-size: 24px; // Увеличиваем размер шрифта для цены
  font-weight: bold;
  color: #007BFF; // Цвет цены
`;

const ContinueButton = styled.button`
  background-color: #FCD900;
  color: black;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  width: 80%; // Добавляем ширину
  margin: 0 auto; // Центрируем кнопку
`;

const CallScreen: React.FC<CallScreenProps> = ({ roomId, onClose, onTimerEndOrContinue, userData, onStoreEndCall }) => {

  useEffect(() => {
    // Получаем и обновляем значение `randomCalls` только один раз при монтировании
    const randomCallsValue = localStorage.getItem('randomCalls');
    if (randomCallsValue) {
      const newRandomCalls = Math.max(0, parseInt(randomCallsValue, 10) - 1);
      localStorage.setItem('randomCalls', newRandomCalls.toString());
      console.log("Updated randomCalls:", newRandomCalls);
    }
  }, []); // Запускается один раз за монтирование

  

  const localVideoRef = useRef<HTMLDivElement>(null);
  const remoteVideoRef = useRef<HTMLDivElement>(null);
  const engineRef = useRef<IRTCEngine | null>(null);
  const [balance, setBalance] = useState(0);
  const [isRemoteVideoConnected, setIsRemoteVideoConnected] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [timer, setTimer] = useState(30);

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };


  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  useEffect(() => {
    logAmplitudeEvent('e.3.0 - Connecting', { component: 'e.3.0 - Connecting' });
  }, []);

// Применяем стили: если не мобильное устройство, ширина 25% и центрируем
const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

useEffect(() => {
  const intervalId = setInterval(() => {
    const currentBalance = parseInt(localStorage.getItem('balance') || '0', 10);
    const randomCalls = parseInt(localStorage.getItem('randomCalls') || '0', 10);

    if (currentBalance < 20 && randomCalls === 0) {
      setShowOverlay(true);
    } else {
      setShowOverlay(false);
    }

    console.log(`Balance check: ${currentBalance}, RandomCalls: ${randomCalls}`);
  }, 1000); // Проверяем каждую секунду

  // Очистка интервала при размонтировании компонента
  return () => clearInterval(intervalId);
}, []);

useEffect(() => {
  // Получаем значение randomCalls из localStorage при монтировании
  const randomCallsValue = localStorage.getItem('randomCalls');
  const randomCalls = randomCallsValue ? parseInt(randomCallsValue, 10) : 0;

  // Если randomCalls уже 0 или его нет, сразу возвращаемся и ничего не делаем через 30 секунд
  if (randomCalls === 0) {
    return;
  }

  // Если randomCalls больше 0, устанавливаем таймер на 30 секунд
  const timerId = setTimeout(() => {
    const updatedRandomCallsValue = localStorage.getItem('randomCalls');
    const updatedRandomCalls = updatedRandomCallsValue ? parseInt(updatedRandomCallsValue, 10) : 0;

    // Проверяем значение randomCalls после 30 секунд
    if (updatedRandomCalls > 0) {
      // Если randomCalls больше 0, вызываем onClose
      logAmplitudeEvent('e.3.0.2 - Videocaht finised', { component: 'e.3.0.2 - Videocaht finised' });
      //onClose();
      onStoreEndCall();
    } else {
      // Если randomCalls равно 0, вызываем onTimerEndOrContinue
      logAmplitudeEvent('e.3.0.2.1 - Videocaht finised after 30 sec from free call', { component: 'e.3.0.2.1 - Videocaht finised after 30 sec from free call' });
      onTimerEndOrContinue();
    }
  }, 30000); // 30 секунд

  // Очистка таймера при размонтировании компонента
  return () => clearTimeout(timerId);
}, [onClose, onTimerEndOrContinue]);



useEffect(() => {
  // Сразу списываем 5 монет
  const initialBalanceValue = localStorage.getItem('balance');
  if (initialBalanceValue) {
    const oldBalance = parseInt(initialBalanceValue, 10); // Баланс до списания
    const newInitialBalance = Math.max(0, oldBalance - 5); // Новый баланс после списания
    localStorage.setItem('balance', newInitialBalance.toString());
    setBalance(newInitialBalance); // Обновляем состояние баланса

    // Логируем изменения
    console.log(
      `Время: ${new Date().toLocaleTimeString()} - Было: ${oldBalance}, Стало: ${newInitialBalance}, Списано: 5`
    );

    // Проверяем, нужно ли показать overlay
    if (newInitialBalance < 20 && parseInt(localStorage.getItem('randomCalls') || '0', 10) === 0) {
   //   setShowOverlay(true);
    }
  }

  // Таймер на 30 секунд для списания 30 монет
  const firstTimeoutId = setTimeout(() => {
    const balanceValue = localStorage.getItem('balance');
    if (balanceValue) {
      const oldBalance = parseInt(balanceValue, 10); // Баланс до списания
      const newBalance = Math.max(0, oldBalance - 30); // Новый баланс после списания
      localStorage.setItem('balance', newBalance.toString());
      setBalance(newBalance); // Обновляем состояние баланса

      // Логируем изменения
      console.log(
        `Время: ${new Date().toLocaleTimeString()} - Было: ${oldBalance}, Стало: ${newBalance}, Списано: 30`
      );

      // Проверяем, нужно ли показать overlay
      if (newBalance < 20 && parseInt(localStorage.getItem('randomCalls') || '0', 10) === 0) {
     //   setShowOverlay(true);
      }
    }

    // Устанавливаем интервал для списания 60 монет каждые 60 секунд
    const intervalId = setInterval(() => {
      const balanceValue = localStorage.getItem('balance');
      if (balanceValue) {
        const oldBalance = parseInt(balanceValue, 10); // Баланс до списания
        const newBalance = Math.max(0, oldBalance - 60); // Новый баланс после списания
        localStorage.setItem('balance', newBalance.toString());
        setBalance(newBalance); // Обновляем состояние баланса

        // Логируем изменения
        console.log(
          `Время: ${new Date().toLocaleTimeString()} - Было: ${oldBalance}, Стало: ${newBalance}, Списано: 60`
        );

        // Проверяем, нужно ли показать overlay
        if (newBalance < 20 && parseInt(localStorage.getItem('randomCalls') || '0', 10) === 0) {
        //  setShowOverlay(true);
        }
      }
    }, 60000); // Интервал 60 секунд

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, 30000); // Таймер 30 секунд

  // Очищаем таймер при размонтировании компонента
  return () => clearTimeout(firstTimeoutId);
}, []);




  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');

    

    const fetchTokenAndJoinRTC = async () => {
      try {
        console.log('Fetching token for room:', roomId, 'user:', userIdFromCookie);
    
        const response = await axios.post('https://web.golive.mobi/generateToken', {
          room_id: roomId,
          user_id: userIdFromCookie || 'defaultUser',
        });
    
        const token = response.data.token;
        console.log('Token received:', token);
    
        // Создаем объект BytePlusRTC Engine
        const engine = BytePlusRTC.createEngine('64216351f52a61012cc78309');
        engineRef.current = engine;
        
        
            
        console.log('Engine created.');

     
    
        // Устанавливаем конфигурацию видеокодека VP8
        await engine.setVideoEncoderConfig({

          width: 1280, // Задайте подходящее разрешение
          height: 720,
          frameRate: 30,
          maxKbps: 25000,
          contentHint: 'motion',
          
        });
    
     
    
        await engine.joinRoom(
          token,
          roomId,
          { userId: userIdFromCookie || 'defaultUser' },
          {
            isAutoPublish: true,
            isAutoSubscribeAudio: true,
            isAutoSubscribeVideo: true,
            roomProfileType: RoomProfileType.communication,
            
          }
        );
    
        console.log('Joined room:', roomId);

        
    
        await engine.setVideoCaptureDevice("user");
    
        setTimeout(async () => {
          await engine.startAudioCapture();
          await engine.startVideoCapture();
    
          console.log('Started audio and video capture.');
    
          if (localVideoRef.current) {
            engine.setLocalVideoPlayer(StreamIndex.STREAM_INDEX_MAIN, {
              renderDom: localVideoRef.current,
            });
            console.log('Local video player set.');
          }
    
          await engine.publishStream(MediaType.AUDIO_AND_VIDEO);
          console.log('Published local stream.');
          logAmplitudeEvent('e.3.0.1 - Videochat started', { component: 'e.3.0.1 - Videochat started' });
        }, 2000);
    
        // Обработка событий публикации потока другим пользователем
        engine.on(BytePlusRTC.events.onUserPublishStream, handleUserPublishStream);
    
      } catch (error) {
        console.error('Failed to join the RTC channel:', error);
      }
    };

    const handleUserPublishStream = async (e: { userId: string; mediaType: MediaType; }) => {
      console.log('User published stream:', e);
      if (e.mediaType === MediaType.AUDIO_AND_VIDEO) {
        const player = remoteVideoRef.current;

        if (player && engineRef.current) {

          await engineRef.current.setRemoteVideoPlayer(StreamIndex.STREAM_INDEX_MAIN, {
            userId: e.userId,
            renderDom: player,
          });
          console.log('Remote video player set.');
          setIsRemoteVideoConnected(true); // Обновляем состояние
        } else {
          console.warn('Remote video player could not be set. Player or engineRef is null.');
        }
      }
    };

    fetchTokenAndJoinRTC();

    const intervalId = setInterval(async () => {
      if (userIdFromCookie) {
        try {
          const response = await fetch('https://golive.mobi/api/coin/status/', {
            method: 'POST',
            headers: {
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: userIdFromCookie }),
          });
          const data = await response.json();
          console.log('Balance check:', data);

          if (data.success === 1) {
            setBalance(data.balance);
            
            const randomCalls = Number(localStorage.getItem('randomCalls')) || 0; // Получаем значение randomCalls, если его нет, то используем 0
            
            if (randomCalls === 0 && data.balance < 20) {
          //    setShowOverlay(true);
            } else {
           //   setShowOverlay(false);
            }
          }
          
        } catch (error) {
          console.error('Ошибка при получении баланса:', error);
        }
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
      const leaveAndDestroy = async () => {
        if (engineRef.current) {
          try {
            console.log('Leaving room and destroying engine.');
            await engineRef.current.stopAudioCapture();
            await engineRef.current.stopVideoCapture();
            await engineRef.current.leaveRoom();
            BytePlusRTC.destroyEngine(engineRef.current);
            engineRef.current = null;
          } catch (error) {
            console.error('Failed to leave the room:', error);
          }
        }
      };
      leaveAndDestroy();
    };
  }, [roomId]);

  const handleClose = async () => {
    console.log('Closing call screen.');
    logAmplitudeEvent('e.3.0.2 - Videocaht finised', { component: 'e.3.0.2 - Videocaht finised' });
    if (engineRef.current) {
      try {
        await engineRef.current.stopAudioCapture();
        await engineRef.current.stopVideoCapture();
        await engineRef.current.leaveRoom();
        BytePlusRTC.destroyEngine(engineRef.current);
        engineRef.current = null;
      } catch (error) {
        console.error('Failed to end the call:', error);
      }
    }
    onClose();
  };

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      console.log('Mutations observed:', mutationsList);
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          setIsRemoteVideoConnected(true);
          observer.disconnect();
          break;
        }
      }
    });

    if (remoteVideoRef.current) {
      console.log('Observing remoteVideoRef for changes...');
      observer.observe(remoteVideoRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);  

  

  useEffect(() => {
    if (showOverlay) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(countdown);
            console.log('Timer ended. Returning to RandomCall.');
            onTimerEndOrContinue();
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [showOverlay, onClose]);

  const handleContinueClick = () => {
    onTimerEndOrContinue(); // Вызов функции перехода в RandomCall
  };

  useEffect(() => {
    // Проверяем, поддерживается ли браузер
    const checkBrowserSupport = async () => {
      const isBrowserSupported = await BytePlusRTC.isSupported();
      console.log("Browser supported:", isBrowserSupported);
    };

    // Получаем список поддерживаемых кодеков и выводим в консоль
    const supportedCodecs = BytePlusRTC.getSupportedCodecs();
    console.log("Supported codecs:", supportedCodecs);

    // Запускаем проверку поддержки браузера
    checkBrowserSupport();
  }, []);



  return (
    <>
    <div style={containerStyle}>
      <GradientBackground ref={remoteVideoRef} style={{  }} />

      {isRemoteVideoConnected ? null : (
        <GradientBackground2 style={{  }}>
          <CenteredTextContainer2>
            <Image2 src={TimeWatch} alt="Time Watch" />
            <MainText2>Connecting</MainText2>
            <SubText2>Connection is in progress</SubText2>
          </CenteredTextContainer2>
        </GradientBackground2>
      )}

{showOverlay && (
  <LowBalanceOverlay>
    <OverlayContent style={{ paddingTop: '20px' }}>
      <img src={topupbalance} alt="Top Up Balance" style={{ width: '90%', height: 'auto', marginBottom: '20px' }} />
      <div style={{ fontSize: '24px', color: '#FF5555', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>
        {`00:${timer.toString().padStart(2, '0')}`}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
        <img src={topup1} alt="Top Up Option 1" style={{ width: '48%', height: 'auto' }} />
        <img src={topup2} alt="Top Up Option 2" style={{ width: '48%', height: 'auto' }} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button
          onClick={handleContinueClick}  // Замените это на действие, которое переводит на RandomCall
          style={{
            backgroundColor: '#FCD900',
            color: 'black',
            fontSize: '20px',
            fontWeight: 'bold',
            padding: '15px 130px',
            border: 'none',
            borderRadius: '50px',
            cursor: 'pointer',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          Continue
        </button>
      </div>
    </OverlayContent>
  </LowBalanceOverlay>
)}

      <Container>
        <TopBar>
          <Icon src={EndCallIcon} alt="End Call" onClick={handleClose} />
          <UserContainer>
            <Avatar src={userData.avatar} alt="Avatar" />
            <UserName>{userData.name}</UserName>
          </UserContainer>
         {/* <Icon src={TranslateIcon} alt="Translate" />
          <Icon src={DotsIcon} alt="Dots" /> */}
        </TopBar>
        <BlueBlock>
          <div
            ref={localVideoRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 1,
              width: '100%',
              height: '100%',
              borderRadius: '25px',
              overflow: 'hidden',
            }}
          />
        </BlueBlock>
       {/*} <BalanceButton>Top up balance</BalanceButton>*/}
        <BottomBar>
        {/*  <RoundButton>
            <ButtonIcon src={GiftIcon} alt="Gift" />
          </RoundButton> */}
          <InputContainer>
            <PlaceholderInput type="text" placeholder="Say something..." />
          </InputContainer>
        {/*  <RoundButton>
            <ButtonIcon src={MicroIcon} alt="Microphone" />
          </RoundButton> */}
        </BottomBar>
      </Container>
      </div>
    </>
  );
};

export default CallScreen;