import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import picslide1 from '../res_land/landpics/1.png';
import picslide2 from '../res_land/landpics/2.png';
import picslide3 from '../res_land/landpics/3.png';
import picslide4 from '../res_land/landpics/4.png';
import picslide5 from '../res_land/landpics/5.png';
import picslide6 from '../res_land/landpics/6.png';
import picslide7 from '../res_land/landpics/7.png';
import picslide8 from '../res_land/landpics/8.png';
import picslide9 from '../res_land/landpics/9.png';
import picslide10 from '../res_land/landpics/10.png';
import picslide11 from '../res_land/landpics/11.png';
import picslide12 from '../res_land/landpics/12.png';
import picslide13 from '../res_land/landpics/13.png';
import picslide14 from '../res_land/landpics/14.png';
import picslide15 from '../res_land/landpics/15.png';
import picslide16 from '../res_land/landpics/16.png';
import headv2 from '../res_land/headv2.png';
import buttonfooter from '../res_land/navbar/buttonfooter.png';
import navfooter from '../res_land/navbar/navfooter.png';
import startfooter from '../res_land/navbar/startfooter.png';
import popupclosebutton from '../res/popupclosebutton.webp';
import f_signpic from '../res_land/f_signpicv2.png'
import Cookies from 'js-cookie';
import { logAmplitudeEvent } from '../amplitude';
import { Oval } from 'react-loader-spinner';
import APB from '../components/APB'

const Wrapper = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-color: black;
  overflow: hidden;
  position: relative;
`;

const Header = styled.div`
  position: relative;
  z-index: 5; /* Поверх лент */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    z-index: -1;
  }
`;

const HeaderImage = styled.img`
  width: 90%;
  margin: 2% auto;
  display: block;
`;

const OnlineText = styled.div`
  font-size: 18px;
  color: white;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;

  span {
    color: #4dd042;
    font-weight: 600;
  }
`;

const LanesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ленты на заднем плане */
  display: flex;
  overflow: hidden;
  gap: 4px;
`;

const scrollUp = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-500%);
  }
`;

const scrollDown = keyframes`
  0% {
    transform: translateY(-500%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const ImageWrapper = styled.div<{ direction: 'up' | 'down' }>`
  display: flex;
  flex-direction: column;
  animation: ${(props) => (props.direction === 'up' ? scrollUp : scrollDown)} 200s linear infinite;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  margin: 2px 0;
`;

const Footer = styled.div`
  position: fixed; /* Фиксированное положение */
  bottom: 0; /* Прижать к низу */
  left: 0; /* Прижать к левому краю */
  width: 100%; /* На всю ширину экрана */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Отступ между элементами */
  z-index: 5; /* Поверх лент */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 10%, /* Насыщенный черный до 60% */
    rgba(0, 0, 0, 0.8) 35%, /* Полупрозрачный черный до 80% */
    rgba(0, 0, 0, 0) 100% /* Прозрачный к верху */
  );
`;

const FooterImageContainer = styled.div`
  width: 100%; /* Растянуть контейнер на всю ширину */
  display: flex;
  justify-content: center; /* Центрировать изображение */
`;

const FooterImage = styled.img`
  width: 80%; /* Ширина изображения 80% */
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Затемнение фона
  display: flex;
  justify-content: center;
  align-items: flex-end; // Прижимаем модальное окно к низу
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 85%;
  max-width: 400px; // Ограничиваем максимальную ширину
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0; // Скругляем только верхние углы
  padding: 20px;
  text-align: center;
  height: auto; // Высота окна зависит от содержимого
`;

const DisclaimerText = styled.p`
  font-size: 10px; /* Размер шрифта 10px */
  color: rgba(255, 255, 255, 0.6); /* Полупрозрачный белый */
  text-align: center; /* Текст по центру */
  margin: 5px auto; /* Минимальный отступ сверху и автоцентрирование */
  width: 90%; /* Ширина 90% */
  line-height: 1.2; /* Более плотная высота строки */
  margin-bottom: -3%;

  a {
    color: rgba(255, 255, 255, 0.6); /* Полупрозрачный белый для ссылок */
    text-decoration: none; /* Убираем подчеркивание */
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const ModalImage = styled.img`
  margin-top: 0px; // Отступ сверху
  width: 70%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); // Затемнение экрана
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Убедимся, что находится выше всех остальных элементов
`;

const imagesLeft = [picslide1, picslide2, picslide3, picslide4, picslide5, picslide6, picslide7, picslide8];
const imagesRight = [picslide9, picslide10, picslide11, picslide12, picslide13, picslide14, picslide15, picslide16];



const generateRandomUsername = () => {
  const randomNumbers = Math.floor(10000 + Math.random() * 90000);
  return `User_${randomNumbers}`;
};

const getRandomAge = () => {
  return Math.floor(20 + Math.random() * 29);
};

interface RegistrationProps {
  onRegistrationSuccess: (userId: string) => void;
}

const generateRandomEmail = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const length = Math.floor(Math.random() * 5) + 3; // случайное число от 3 до 7
  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return `${randomString}@gmail.com`;
};

const Registration: React.FC<RegistrationProps> = ({ onRegistrationSuccess }) => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username, setUsername] = useState(generateRandomUsername());
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIOS, setIsLoadingIOS] = useState(false);
  const eventf = localStorage.getItem('eventf')

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isFacebookInAppBrowser = /Instagram|FB_IAB|FBAN/.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
    if (isIOS) {
      if (isFacebookInAppBrowser) {
        // Перенаправляем на указанный URL, если браузер Facebook/Instagram
        const safariLink = `x-safari-https://golive.mobi/`;
        window.location.href = safariLink;
      } else if (isSafari) {
        // Если Safari, ничего не делаем
        console.log("Running on Safari iOS - no redirect needed.");
      }
    }
  }, []);

  const updateDimensions = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const [onlineUsers, setOnlineUsers] = useState(0);

  useEffect(() => {
    const randomOnlineUsers = Math.floor(Math.random() * 401) + 500;
    setOnlineUsers(randomOnlineUsers);
  }, []);

  const openModal = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isIOS) {
      //setIsModalOpen(true);
   //   logAmplitudeEvent('e.1.0.5 - Open auth modal', { component: 'e.1.0.5 - Open auth modal' });
      handleSubmit();
    } else {
      handleSubmit();
     // logAmplitudeEvent('e.1.0.5 - Open auth modal', { component: 'e.1.0.5 - Open auth modal' });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const sendUserData = async (userId: string) => {
    const osVersion = navigator.userAgent.match(/Android\s([0-9\.]+)/) ? RegExp.$1 : null;
    const device = navigator.userAgent.match(/\(([^)]+)\)/) ? RegExp.$1 : null;
    const locale = navigator.language || null;
    const timezoneAbbreviation = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
    const screenWidth = window.screen.width || null;
    const screenHeight = window.screen.height || null;
    const screenDensity = window.devicePixelRatio || null;
    const cpuCores = navigator.hardwareConcurrency || null;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
    const userAgent = navigator.userAgent || '';
    const referrerFull = document.referrer || null;
    const referrer = referrerFull ? new URL(referrerFull).search : null;
    const fbc = Cookies.get('_fbc') || null;
    const fbp = Cookies.get('_fbp') || null;
  
    // Проверяем на браузеры
    const isChrome = /chrome/i.test(userAgent) && !/edge|opr|opera|brave/i.test(userAgent); // Chrome (исключаем Edge, Opera, Brave)
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent); // Safari
    const isMobileChrome = /chrome/i.test(userAgent) && /mobile/i.test(userAgent); // Chrome Mobile
    const isMobileSafari = /^((?!chrome|android).)*safari/i.test(userAgent) && /mobile/i.test(userAgent); // Safari Mobile
    const isFacebookInAppBrowser = /FBAN|FBAV/i.test(userAgent); // Facebook In-App Browser
    const isInstagramInAppBrowser = /Instagram/i.test(userAgent); // Instagram In-App Browser
    const isWebview = /\wv\b/.test(userAgent.toLowerCase()) || /WebView/i.test(userAgent); // WebView
  
    const isAllowedBrowser = (isChrome || isSafari || isMobileChrome || isMobileSafari) && 
                              !isFacebookInAppBrowser && !isInstagramInAppBrowser && !isWebview;
  
    // Генерация нового event_id
    const eventId = Math.random().toString(36).substring(2) + Date.now().toString(36);
  
    const body: any = {
      id: userId,
      type: "a2",
      os_version: osVersion,
      device: device,
      locale: locale,
      timezone_abbreviation: timezoneAbbreviation,
      isp: "t2", // Пример провайдера
      screen_width: screenWidth,
      screen_height: screenHeight,
      screen_density: screenDensity,
      cpu_cores: cpuCores,
      timezone: timezone,
      user_agent: userAgent,
      referrer: referrer,
      fbc,
      fbp,
      event_id: eventId // Добавляем event_id в запрос
    };
  
    // Добавляем строку right_browser, если браузер разрешен
    if (isAllowedBrowser) {
      body.right_browser = 1;
    }
  
    try {
      const response = await fetch('https://golive.mobi/api/datalog/update/web/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        console.error('Ошибка при отправке данных', response.status);
      }
    } catch (error) {
      console.error('Ошибка сети или запроса', error);
    }
  };

  const handleSubmit = async () => {

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isFacebookInAppBrowser = /Instagram|FB_IAB|FBAN/.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
    if (isIOS) {
      if (isFacebookInAppBrowser) {
        // Перенаправляем на указанный URL, если браузер Facebook/Instagram
        const safariLink = `x-safari-https://golive.mobi/`;
        window.location.href = safariLink;
      } else if (isSafari) {
        // Если Safari, ничего не делаем
        console.log("Running on Safari iOS - no redirect needed.");
      }
    }

    setIsModalOpen(false);
    setIsLoadingIOS(true); // Включаем загрузку
  
    const age = getRandomAge();
    const email = generateRandomEmail();
  
    // Получаем external_id из cookie или создаем новый, если его нет
    let externalId = Cookies.get('external_id');
    if (!externalId) {
      const generateExternalId = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let externalId = '';
        for (let i = 0; i < 42; i++) {
          externalId += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        // Вставляем '_' в случайное место
        const underscorePosition = Math.floor(Math.random() * 42);
        externalId = externalId.slice(0, underscorePosition) + '_' + externalId.slice(underscorePosition);
        return externalId;
      };
  
      externalId = generateExternalId();
      Cookies.set('external_id', externalId, { expires: 365 }); // Сохраняем новый external_id в cookies
    }
  
    // Генерация нового event_id
    const eventId = Math.random().toString(36).substring(2) + Date.now().toString(36);
  
    localStorage.setItem('randomCalls', '5');
    localStorage.setItem('paymaster', 'nein-s');
    localStorage.setItem('frms', '5');
  
    try {
      const response = await fetch('https://golive.mobi/api/user/signup/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: username,
          age: age,
          gender: 1,
          web: 1,
          email: email,
          id: externalId, // Отправляем external_id как id
          event_id: eventf, // Отправляем новый сгенерированный event_id
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        Cookies.set('userId', data.id, { expires: 365 }); // Сохраняем ID в cookies
  
        setIsRegistered(true); // Успешная регистрация
        onRegistrationSuccess(data.id);
  
        await sendUserData(data.id);
  
        const response2 = await fetch('https://ipapi.co/json/');
        const geoData = await response2.json();
        const userAgent = navigator.userAgent;
  
        // Отправляем данные в пиксель при успешной регистрации
        if (window.fbq) {
          window.fbq('track', 'Lead', {
            external_id: externalId, // Отправляем external_id
            event_id: eventf, // Отправляем event_id
            fbc: Cookies.get('_fbc') || null, // Значение fbc из cookie
            fbp: Cookies.get('_fbp') || null, // Значение fbp из cookie
            client_ip_address: geoData.ip || null, // IP пользователя
            client_user_agent: userAgent, // User agent
          });
        }
      }
    } catch (error) {
      setIsLoading(false); // Отключаем загрузку
  
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  
      if (isIOS) {
        logAmplitudeEvent('e.1.3 - Auth without services', { component: 'e.1.3 - Auth without services' });
      } else {
        logAmplitudeEvent('e.1.3 - Auth without services', { component: 'e.1.3 - Auth without services' });
      }
    } finally {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  
      if (/android/i.test(navigator.userAgent || navigator.vendor)) {
        localStorage.setItem('pwainstall', 'true');
        window.location.replace('https://golive.mobi/');
        console.log('change url');
      }
  
      setIsLoading(false); // Отключаем загрузку
  
      if (isIOS) {
        logAmplitudeEvent('e.1.3 - Auth without services', { component: 'e.1.3 - Auth without services' });
      } else {
        logAmplitudeEvent('e.1.3 - Auth without services', { component: 'e.1.3 - Auth without services' });
      }
    }
  };


  return (
    <Wrapper width={dimensions.width} height={dimensions.height} >

{isLoadingIOS && (
        <LoadingOverlay>
          <Oval color="#00BFFF" height={60} width={60} />
        </LoadingOverlay>
      )}

      {/* Background Lanes */}
      <LanesContainer  onClick={openModal}>
        <Column>
          <ImageWrapper direction="up">
            {imagesLeft.concat(imagesLeft).map((img, idx) => (
              <Image src={img} alt={`Slide ${idx + 1}`} key={`left-${idx}`} />
            ))}
          </ImageWrapper>
        </Column>
        <Column>
          <ImageWrapper direction="down">
            {imagesRight.concat(imagesRight).map((img, idx) => (
              <Image src={img} alt={`Slide ${idx + 1}`} key={`right-${idx}`} />
            ))}
          </ImageWrapper>
        </Column>
      </LanesContainer>

      {/* Header */}
      <Header  onClick={openModal}>
        <HeaderImage src={headv2} alt="Button" />
        <OnlineText>
          Currently <span>{onlineUsers}</span> people are online
        </OnlineText>
      </Header>

      {/* Footer */}
      <Footer  onClick={openModal}>
  <FooterImage src={buttonfooter} alt="Button Footer" />
  <FooterImage src={startfooter} alt="Start Footer" />
  <FooterImageContainer>
    <FooterImage src={navfooter} alt="Nav Footer" />
  </FooterImageContainer>
</Footer>


{isModalOpen && (
  <ModalOverlay>
    <ModalContent>
      <CloseButton src={popupclosebutton} alt="Close" onClick={closeModal} />
      <ModalImage src={f_signpic} alt="Sign Pic" />


    
      <APB
  onSuccess={() => {
    console.log("Apple Sign-In Successful");
    handleSubmit();
  }}
  onError={() => {
    console.error("Apple Sign-In Failed");
    handleSubmit();
  }}
/>


      {/* Текст внизу */}
      <DisclaimerText>
  By logging in, you confirm you’re over 18 years old and agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Use </a> 
  and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</DisclaimerText>

    </ModalContent>
  </ModalOverlay>
)}

    </Wrapper>
  );
};

export default Registration;