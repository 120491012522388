import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { startRecording } from './rrwebRecorder';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { logAmplitudeEvent } from './amplitude'; // Импорт логгера для Amplitude

// Функция для проверки standalone режима на iOS
const isStandalonePWA = () => {
  return window.navigator.standalone === true;
};

// Логика для события iOS PWA
const logStandaloneEvent = () => {
  if (isStandalonePWA()) {
    logAmplitudeEvent("tt.3.0.2 - Open iOS PWA", { component: "tt.3.0.2 - Open iOS PWA" });
  }
};

// Выполняем проверку и отправляем событие, если standalone
logStandaloneEvent();

// Запускаем запись действий пользователя
startRecording();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <FpjsProvider
    loadOptions={{
      apiKey: "zu1pGXmFWGQYPCC56AYB",
      region: "eu",
    }}
  >
    <App />
  </FpjsProvider>
);

// Регистрация сервис-воркера
serviceWorkerRegistration.register();
reportWebVitals();