import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import splashLogo from '../res/logo_splash.webp';
import { initializeAmplitude, logAmplitudeEvent, setAmplitudeUserProperties } from '../amplitude';
import Cookies from 'js-cookie';
import f_bar2 from '../res_land/f_bar2.webp';
import picslide1 from '../res_land/landpics/1.png';
import picslide2 from '../res_land/landpics/2.png';
import picslide3 from '../res_land/landpics/3.png';
import picslide4 from '../res_land/landpics/4.png';
import picslide5 from '../res_land/landpics/5.png';
import picslide6 from '../res_land/landpics/6.png';
import picslide7 from '../res_land/landpics/7.png';
import picslide8 from '../res_land/landpics/8.png';
import picslide9 from '../res_land/landpics/9.png';
import picslide10 from '../res_land/landpics/10.png';
import picslide11 from '../res_land/landpics/11.png';
import picslide12 from '../res_land/landpics/12.png';
import picslide13 from '../res_land/landpics/13.png';
import picslide14 from '../res_land/landpics/14.png';
import picslide15 from '../res_land/landpics/15.png';
import picslide16 from '../res_land/landpics/16.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const Info = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;

const StartApp: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  const [fbp, setFbp] = useState<string | null>(null);
  const [fbc, setFbc] = useState<string | null>(null);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isFacebookInAppBrowser = /Instagram|FB_IAB|FBAN/.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
    if (isIOS) {
      if (isFacebookInAppBrowser) {
        // Перенаправляем на указанный URL, если браузер Facebook/Instagram
        const safariLink = `x-safari-https://golive.mobi/`;
        window.location.href = safariLink;
      } else if (isSafari) {
        // Если Safari, ничего не делаем
        console.log("Running on Safari iOS - no redirect needed.");
      }
    }
  }, []);

  const eventId = Math.random().toString(36).substring(2) + Date.now().toString(36);
  localStorage.setItem('eventf', eventId);

  const purchaseId = Math.random().toString(36).substring(2) + Date.now().toString(36);
  localStorage.setItem('purchasef', purchaseId);

  useEffect(() => {
    // Инициализация Amplitude и вывод device_id
    initializeAmplitude();

    const device_id = localStorage.getItem('device_id'); // Чтение device_id из localStorage после инициализации
    if (device_id) {
      console.log("Device ID:", device_id);
    }

    logAmplitudeEvent('e.0.0 - Initialization/Login to the site', {
      component: 'e.0.0 - Initialization/Login to the site',
    });

    // Устанавливаем свойства пользователя для Amplitude
    setAmplitudeUserProperties({
      os_version: navigator.userAgent.match(/Android\s([0-9\.]+)/) ? RegExp.$1 : 'Unknown',
      device_model: navigator.userAgent.match(/\(([^)]+)\)/) ? RegExp.$1 : 'Unknown',
      locale: navigator.language || 'Unknown',
      screen_height: window.screen.height || 'Unknown',
      screen_density: window.devicePixelRatio || 'Unknown',
      cpu_cores: navigator.hardwareConcurrency || 'Unknown',
      user_agent: navigator.userAgent || 'Unknown',
      timezone_abbreviation: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown',
    });

    const fbpCookie = Cookies.get('_fbp') || null;
    const fbcCookie = Cookies.get('_fbc') || null;
    setFbp(fbpCookie);
    setFbc(fbcCookie);

    setCurrentUrl(window.location.href);

    const urlParams = new URLSearchParams(window.location.search);
    const redParam = urlParams.get('red');
    const testsubsParam = urlParams.get('testsubs');

    if (redParam === 'no') {
      setShowInfo(false);
    }

    if (testsubsParam === 'true') {
      localStorage.setItem('testsubs', 'true');
    }

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isFacebookInAppBrowser = /Instagram|FB_IAB|FBAN/.test(navigator.userAgent);
    
    if (!(isIOS && /Instagram|FB_IAB|FBAN/.test(navigator.userAgent)) && (isAndroid || !isIOS)) {
      onTimeout();
    }

    // Таймер для вызова onTimeout, без перезагрузки страницы
    const timer = setTimeout(() => {
      onTimeout();
    }, 1000);

    return () => clearTimeout(timer);
  }, [onTimeout]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fbpParam = urlParams.get('fbp');
    const fbcParam = urlParams.get('fbc');

    if (fbpParam) {
      Cookies.set('_fbp', fbpParam, { expires: 365 });
      setFbp(fbpParam);
    }

    if (fbcParam) {
      Cookies.set('_fbc', fbcParam, { expires: 365 });
      setFbc(fbcParam);
    }
  }, []);

  

  return (
    <Container>
     
<img src={picslide1} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide2} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide3} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide4} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide5} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide6} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide7} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide8} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide9} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide10} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide11} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide12} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide13} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide14} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide15} style={{ width: '1px', height: '1px', opacity: 0 }} />
<img src={picslide16} style={{ width: '1px', height: '1px', opacity: 0 }} />


      {!showInfo && (
        <>
          <Info>FBP: {fbp ? fbp : 'Not found'}</Info>
          <Info>FBC: {fbc ? fbc : 'Not found'}</Info>
          <Info>Текущий URL: {currentUrl}</Info>
        </>
      )}
    </Container>
  );
};

export default StartApp;